export class Send {
	nome: string;
	email: string;
	assunto: string;
	comentario: string;

	constructor(nome: string, email: string, assunto: string, comentario: string) {
			this.nome = nome;
			this.email = email;
			this.assunto = assunto;
			this.comentario = comentario;
   			}
}
