import { BrowserModule } from '@angular/platform-browser';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';

import { HttpClientModule } from '@angular/common/http';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import { HeaderComponent } from './header/header.component';
import { ContentComponent } from './content/content.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { SobreComponent } from './sobre/sobre.component';
import { ContatoComponent } from './contato/contato.component';
import { ProdutosComponent } from './produtos/produtos.component';
import { PlantaoFacilComponent } from './produtos/plantao-facil/plantao-facil.component';
import { PlacaFipeComponent } from './produtos/placa-fipe/placa-fipe.component';
import { PlacaPolicyComponent } from './produtos/placa-fipe/placa-policy/placa-policy.component';
import { PlantaoPolicyComponent } from './produtos/plantao-facil/plantao-policy/plantao-policy.component';
import { KMilkComponent } from './produtos/k-milk/k-milk.component';
import { KmilkPolicyComponent } from './produtos/k-milk/kmilk-policy/kmilk-policy.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    SobreComponent,
    ContatoComponent,
    ProdutosComponent,
    PlantaoFacilComponent,
    PlacaFipeComponent,
    PlacaPolicyComponent,
    PlantaoPolicyComponent,
    KMilkComponent,
    KmilkPolicyComponent
  ],
  imports: [
      BrowserModule,
  	   BrowserAnimationsModule,
  	   MatDialogModule,
      AppRoutingModule,
     HttpClientModule,
FormsModule,
ReactiveFormsModule
   

  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
