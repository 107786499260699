import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../helper/must-match.validator';
import {MatDialog} from '@angular/material';


import {Resposta} from '../resposta/resposta'
import {Send} from '../resposta/send'
 

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded',
  	  })
  };

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})

export class ContatoComponent implements OnInit {
  form;
  success;
  failure;
  resp: Resposta;

  constructor(
  	private http: HttpClient,
  	private fb: FormBuilder,
  	public dialog: MatDialog) {
   }

  ngOnInit() {

  this.success = false;
  this.failure = false;


  this.form = this.fb.group({
	  nome: ['', Validators.required],
	  email: ['',  [Validators.required, Validators.email]],
	  assunto: [''],
	  comentario: ['', Validators.required]
	});

  }

  get f() { return this.form.controls; }

  get nome() { return this.form.get('nome'); }

contato(){

   if(this.form.invalid){
   		if(this.nome.status == "INVALID"){
   			this.nome.markAsDirty();
   		}
   		if(this.f.email.status == "INVALID"){
   			this.f.email.markAsDirty();
   		}
   		if(this.f.comentario.status == "INVALID"){
   			this.f.comentario.markAsDirty();
   		}
   		return
   }



   var send = new Send(this.f.nome.value, this.f.email.value, this.f.assunto.value, this.f.comentario.value)

   this.http.post('https://murieltecnologia.com.br/teste/', send, httpOptions).subscribe(data => 
   			{
	  			 this.resp = {
	       		 	retorno: data['retorno'],
	       		 	erro: data['erro'],
	    		 }

	    		if(this.resp.retorno == "ok"){
	    			this.success = true;
	    			this.failure = false;
	    			this.form.reset();
	    		}else{
	    			this.failure = true;
	    			this.success = false;
	    		}
	    		return data
	    	}
    );
}








}
