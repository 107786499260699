import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-plantao-facil',
  templateUrl: './plantao-facil.component.html',
  styleUrls: ['./plantao-facil.component.css']
})
export class PlantaoFacilComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
