import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ContentComponent } from './../content/content.component';
import { SobreComponent } from './../sobre/sobre.component';
import { ContatoComponent } from './../contato/contato.component';
import { ProdutosComponent } from '../produtos/produtos.component';
import { PlantaoFacilComponent } from '../produtos/plantao-facil/plantao-facil.component';
import { PlantaoPolicyComponent } from '../produtos/plantao-facil/plantao-policy/plantao-policy.component';
import { PlacaFipeComponent } from '../produtos/placa-fipe/placa-fipe.component';
import { PlacaPolicyComponent } from '../produtos/placa-fipe/placa-policy/placa-policy.component';

import { KMilkComponent } from '../produtos/k-milk/k-milk.component';
import { KmilkPolicyComponent } from '../produtos/k-milk/kmilk-policy/kmilk-policy.component';

const routes: Routes = [
{ path: '', component: ContentComponent },
{ path: 'sobre', component: SobreComponent },
{ path: 'contato', component: ContatoComponent },
{ path: 'produtos', component: ProdutosComponent },
{ path: 'produtos/PlantaoFacil', component: PlantaoFacilComponent },
{ path: 'produtos/PlantaoFacil/politica-de-privacidade', component: PlantaoPolicyComponent },
{ path: 'produtos/PlacaFipe', component: PlacaFipeComponent },
{ path: 'produtos/PlacaFipe/politica-de-privacidade', component: PlacaPolicyComponent },
{ path: 'produtos/PlacaFip', component: PlacaFipeComponent },
{ path: 'produtos/PlacaFip/politica-de-privacidade', component: PlacaPolicyComponent },

{ path: 'produtos/K-Milk', component: KMilkComponent },
{ path: 'produtos/K-Milk/politica-de-privacidade', component: KmilkPolicyComponent },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
