import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-k-milk',
  templateUrl: './k-milk.component.html',
  styleUrls: ['./k-milk.component.css']
})
export class KMilkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
